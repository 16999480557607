import { FieldProp } from 'redux-form'
import { Callout, TextLink } from 'baby-design'
import { settingsRegistryPath } from 'lib/urls'
import { ConsolidationText, RadioGroup } from './components'
import css from './consolidated-shipping-form-fields.scss'

interface FormFieldProps {
  consolidationChoice: FieldProp<string>
}

export default ({ consolidationChoice }: FormFieldProps) => (
  <div className={css.ConsolidatedShippingFormFields}>
    <ConsolidationText />
    <Callout
      backgroundColor="grey"
      text={
        <span className={css.ConsolidatedShippingFormFields__calloutText}>
          We'll automatically bundle your items until 14 days before your baby's{' '}
          <TextLink
            underline
            href={settingsRegistryPath}
            innerContentClassName={
              css.ConsolidatedShippingFormFields__calloutText__link
            }
            size="md"
          >
            arrival date
          </TextLink>
          .
        </span>
      }
      title="No gifts left behind!"
    />
    <RadioGroup consolidationChoice={consolidationChoice} />
  </div>
)
