import React from 'react'
import { CONSOLIDATED_SHIPPING_COPY } from 'src/constants'
import { RadioField } from '..'
import { RadioGroupProps } from './RadioGroup.types'
import css from './RadioGroup.styles.scss'

const RadioGroup: React.FC<RadioGroupProps> = ({ consolidationChoice }) => {
  const { error, ...consolidationChoiceInputProps } = consolidationChoice
  const isNone =
    !consolidationChoice.value || consolidationChoice.value === 'none'

  return (
    <div className={css.RadioGroup} role="radiogroup">
      {error && <span className={css.RadioGroup__error}>Must select one:</span>}
      <RadioField
        recommended
        checked={consolidationChoice.value === 'rolling'}
        consolidationChoiceInputProps={consolidationChoiceInputProps}
        description="Free shipping on Babylist Shop items!* Items shipped every 2 weeks in fewer boxes."
        label={CONSOLIDATED_SHIPPING_COPY}
        onChange={() => consolidationChoiceInputProps.onChange('rolling')}
      />
      <RadioField
        checked={isNone}
        consolidationChoiceInputProps={consolidationChoiceInputProps}
        description="Free shipping on Babylist Shop orders over $45*. Items shipped individually."
        label="Standard shipping"
        onChange={() => consolidationChoiceInputProps.onChange('none')}
      />
    </div>
  )
}

export default RadioGroup
