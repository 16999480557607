import * as api from 'cart/api'

import { Modal, ModalBody } from 'components/modal'
import { useState } from 'react'
import { errorMessage, infoMessage } from 'lib/flash-message'
import { get, partition } from 'lodash'
import { track, useTracking } from 'lib/analytics'
import { useQueryClient } from '@tanstack/react-query'
import { CACHE_KEY } from 'src/routes/(shop)/api/cart'

import PropTypes from 'prop-types'
import { UPDATE_CART_SUMMARY, FREE_SHIPPING_THRESHOLD } from 'shared/constants'
import { connect } from 'react-redux'
import { getProductRecommendations } from 'cart/reducers'
import { buildCartItems, getSubTotal } from 'cart/lib'
import { numberToCurrency } from 'lib/money'
import Card from 'components/card'
import { mapHeroesToProductCards } from 'store/components/product-grid'
import ProductRecommendationSlider from 'cart/components/product-recommendations-slider'
import RemoveBLCartItem from '../remove-bl-cart-item'
import OutOfStockCart from '../out-of-stock-cart'
import BLCartOrder from '../bl-cart-order'
import { RegistryShape, CartShape } from '../../lib/prop-types'

const BLCartContent = ({
  maintenanceMode,
  registry,
  cart,
  productRecommendations,
  reservations,
  unpurchasableReservations,
  onRemoveItem,
  isMobile,
}) => {
  const [outOfStockItems, inStockItems] = partition(
    cart.items,
    (item) => item.isOutOfStock
  )

  // Calculate order amounts
  const cartItems = buildCartItems(null, cart, reservations, 'Babylist', false)
  const subTotal = getSubTotal(cartItems)
  const totalNeededForFreeShipping = FREE_SHIPPING_THRESHOLD - subTotal

  const cartWithInStockItems = { ...cart, items: inStockItems }
  const hasStoreItems = inStockItems.length > 0
  const hasReservations = reservations.length > 0
  const hasOutOfStockItems = outOfStockItems.length > 0
  const hasUnpurchasableItems = unpurchasableReservations.length > 0

  const recommendationTitle =
    subTotal >= FREE_SHIPPING_THRESHOLD
      ? `More Items We Think You'll Love.`
      : `So close! Add ${numberToCurrency(
          totalNeededForFreeShipping
        )} more to qualify for Free Shipping.`

  let content

  switch (true) {
    case hasStoreItems || hasReservations:
      content = (
        <BLCartOrder
          cart={cartWithInStockItems}
          footerComponent={
            <div>
              {!!productRecommendations.length && (
                <div className="clearfix">
                  <div className="col-md-8">
                    <ProductRecommendationSlider
                      isMobile={isMobile}
                      originalCartSubTotal={parseInt(subTotal.toFixed(2))}
                      products={productRecommendations.map((p) =>
                        mapHeroesToProductCards(p)
                      )}
                      title={recommendationTitle}
                    />
                  </div>
                  <div className="col-md-4" />
                </div>
              )}
              {(hasOutOfStockItems || hasUnpurchasableItems) && (
                <Card.Footer background="muted">
                  <OutOfStockCart
                    items={outOfStockItems}
                    unpurchasableReservations={unpurchasableReservations}
                    onRemoveItem={onRemoveItem}
                  />
                </Card.Footer>
              )}
            </div>
          }
          maintenanceMode={maintenanceMode}
          registry={registry}
          reservations={reservations}
          taxRate={get(registry, 'shippingAddress.taxRate', 0)}
          useRegistryDiscount={
            cart.isEligibleForRegistryDiscount && cart.applyRegistryDiscount
          }
          onRemoveCartItem={onRemoveItem}
        />
      )
      break
    case hasOutOfStockItems || hasUnpurchasableItems:
      content = (
        <Card>
          <OutOfStockCart
            items={outOfStockItems}
            unpurchasableReservations={unpurchasableReservations}
            onRemoveItem={onRemoveItem}
          />
        </Card>
      )
      break
    default:
      content = null
      break
  }

  return content
}

const Cart = ({
  cart,
  maintenanceMode,
  productRecommendations,
  registry,
  reservations,
  unpurchasableReservations,
  isMobile,
}) => {
  const queryClient = useQueryClient()
  const tracker = useTracking()
  const [cartItemToRemove, setCartItemToRemove] = useState(null)

  const handleRemoveCartItem = () => {
    tracker.trackEvent({
      cartItem: cartItemToRemove,
      eventType: cartItemToRemove.isOutOfStock
        ? track.EventType.ITEM_REMOVED_MINUS_OUT_OF_STOCK
        : track.EventType.ITEM_REMOVED,
    })
    api
      .removeCartItem(cartItemToRemove)
      .then(() => {
        setCartItemToRemove(null)
        PubSub.publish(UPDATE_CART_SUMMARY)
        queryClient.invalidateQueries(CACHE_KEY)
        infoMessage('Your item has been removed!')
      })
      .catch(() => {
        errorMessage()
      })
  }

  return (
    <>
      <div className="pbxl">
        <BLCartContent
          cart={cart}
          isMobile={isMobile}
          maintenanceMode={maintenanceMode}
          productRecommendations={productRecommendations}
          registry={registry}
          reservations={reservations}
          unpurchasableReservations={unpurchasableReservations}
          onRemoveItem={setCartItemToRemove}
        />
      </div>
      {cartItemToRemove && (
        <Modal
          show={Boolean(cartItemToRemove)}
          title="Remove from Cart?"
          onHide={setCartItemToRemove}
        >
          <ModalBody>
            <RemoveBLCartItem
              cartItem={cartItemToRemove}
              onHide={setCartItemToRemove}
              onRemove={handleRemoveCartItem}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

Cart.propTypes = {
  cart: PropTypes.shape(CartShape).isRequired,
  maintenanceMode: PropTypes.bool.isRequired,
  registry: PropTypes.shape(RegistryShape).isRequired,
  reservations: PropTypes.arrayOf(PropTypes.object).isRequired,
  unpurchasableReservations: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }),
}

Cart.defaultProps = {
  productRecommendations: [],
  user: null,
}

const mapStateToProps = (state) => ({
  productRecommendations: getProductRecommendations(state),
})

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, {})(Cart)
