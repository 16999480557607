import { apiV3UserSessionPath } from 'lib/urls'

export interface BabylistLoginErrorResponseV3 {
  error: {
    message: string
  }
}

export const postLoginData = (
  credential: string | undefined,
  email: string | undefined,
  password: string | undefined,
  recaptchaCode: string | undefined,
  redirectUrl = '/'
) =>
  fetch(apiV3UserSessionPath, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Key-Inflection': 'camel',
    },
    body: JSON.stringify({
      credential,
      user_session: {
        login: email,
        password,
      },
      authenticity: {
        token: recaptchaCode,
        version: 'v3',
        type: 'recaptcha',
      },
      redirect_url: redirectUrl,
    }),
  })
