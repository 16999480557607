import { RegItem } from 'src/types/regItem'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import useRegItemChanges, {
  RegItemChanges,
} from 'src/routes/(registry)/list/[slug]/hooks/useRegItemChanges'
import { useEffect } from 'react'
import { getCategoriesAsKeyValue } from 'src/routes/(registry)/list/[slug]/utils/productHelpers'
import { CrowdfundForm } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/fields/CrowdfundFields'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, RightDrawer } from 'baby-design'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createRegItem,
  FETCH_REGISTRY_KEY,
  fetchRegistry,
} from 'src/api/queries'
import { errorMessage, successMessage } from 'lib/flash-message'
import CashFundPreferencesDrawer from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/CashFundPreferencesDrawer'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { Registry } from 'src/types/registry'
import RegistryLink from 'src/routes/(shop)/gp/[slug]/[genericProductId]/[productId]/components/PDPPage/CashFund/RegistryLink'
import ConfirmUnsavedChangesModal from '../ConfirmUnsavedChangesModal/ConfirmUnsavedChangesModal'
import css from './AddCashFundDrawer.styles.scss'
import { ProductInfoDrawer } from '../ProductInfoDrawer/ProductInfoDrawer'
import { AddCashFundDrawerProps } from './AddCashFundDrawer.types'
import useCategorizeAnyRegAdd from 'src/hooks/useCategorizeAnyRegAdd'
import { showRegItemAddedToast } from 'shared/RegItemAddedToast/showRegItemAddedToast'
import { track, useTracking } from 'lib/analytics'

export const AddCashFundDrawer = NiceModal.create(
  ({ regItem }: AddCashFundDrawerProps) => {
    const modal = useModal()
    const [currentUser] = useCurrentUser()
    const isCategorizeAnyAddEnabled = useCategorizeAnyRegAdd()
    const tracker = useTracking()

    const registryId = currentUser?.currentRegistry?.id

    const { data: registry, refetch } = useQuery<Registry>({
      enabled: !!registryId,
      queryKey: [FETCH_REGISTRY_KEY, registryId],
      queryFn: () => fetchRegistry(registryId),
    })

    const {
      regItemChanges,
      setRegItemChanges,
      hasUnsavedChanges,
      clearRegItemFields,
      updateRegItemFields,
    } = useRegItemChanges({ regItem, initialChanges: regItem })

    const regItemFields: RegItem = {
      ...regItem,
      ...regItemChanges,
    }

    const createRegItemMutation = useMutation({
      mutationFn: (regItemDiff: RegItemChanges) =>
        createRegItem(registry?.id as number, { ...regItem, ...regItemDiff }),
      onSuccess: ({ regItem: newRegItem }: { regItem: RegItem }) => {
        setRegItemChanges({})
        modal.resolve(newRegItem)
        modal.hide()

        if (isCategorizeAnyAddEnabled) {
          showRegItemAddedToast({
            currentUser,
            eventLocation: track.EventLocation.PDP,
            regItem: {
              ...newRegItem,
              imgUrl: regItemFields.imgUrl,
            },
            tracker,
          })
        } else {
          successMessage(
            <>
              Cash fund added to <RegistryLink registry={registry} />
            </>
          )
        }
      },
      onError: (error: any) => {
        errorMessage(
          error?.error?.message ||
            'Failed to save. Try refreshing the page. If the problem persists, contact support.'
        )
      },
    })

    const categories = getCategoriesAsKeyValue(registry?.categories || [])

    const handleClose = async () => {
      if (createRegItemMutation.isLoading) {
        return
      }

      if (hasUnsavedChanges()) {
        const confirmUnsavedChanges: boolean | undefined = await NiceModal.show(
          ConfirmUnsavedChangesModal
        )

        if (!confirmUnsavedChanges) {
          return
        }
      }

      modal.resolve(undefined)
      modal.hide()
    }

    useEffect(() => {
      setRegItemChanges({})
    }, [modal.visible])

    const openProductInfoDrawer = async () => {
      const response: RegItem | undefined = await NiceModal.show(
        ProductInfoDrawer,
        { regItem }
      )
      if (response) {
        NiceModal.show(AddCashFundDrawer, { regItem: response })
      }
    }

    const openCashFundPreferencesDrawer = async () => {
      const response = await NiceModal.show(CashFundPreferencesDrawer)
      if (response) {
        refetch()
      }
    }

    const hasSelectedCashFundPreference = () =>
      !!registry?.cashFundPreferences?.paymentPreferences?.some(
        (pref) => !!pref.selected
      )

    const handleCreateRegItem = () => {
      if (!hasSelectedCashFundPreference()) {
        errorMessage('You must select cash fund preferences')
        return
      }
      createRegItemMutation.mutate(regItemFields)
    }

    return (
      <RightDrawer
        handleClose={handleClose}
        handleDismount={modal.remove}
        isOpen={modal.visible}
        title="Item Details"
      >
        <CrowdfundForm
          categories={categories}
          clearRegItemFields={clearRegItemFields}
          regItemFields={regItemFields}
          registry={registry}
          updateRegItemFields={updateRegItemFields}
          onEditCashFundPreferences={openCashFundPreferencesDrawer}
          onEditProductInfo={openProductInfoDrawer}
        />
        <Button
          className={css.AddCashFundDrawer__Submit}
          size="md"
          variant="primary"
          onClick={handleCreateRegItem}
        >
          Add to Babylist
        </Button>
      </RightDrawer>
    )
  }
)
