import React from 'react'
import css from './ConsolidationText.styles.scss'

const ConsolidationText: React.FC = () => (
  <div className={css.ConsolidationText}>
    <span className={css.ConsolidationText__introCopy}>
      Choose your default shipping method for gifts purchased from the Babylist
      Shop. Your selection will apply to gifts purchased by friends and family,
      too!
    </span>
  </div>
)

export default ConsolidationText
