import { useState } from 'react'
import { dueDateCalculatorPath } from 'lib/urls'
import { DatePickerInput } from 'components/forms'
import { isValid, startOfToday, sub } from 'date-fns'
import css from './DueDateForm.scss'
import {
  Option,
  METHOD_OPTIONS,
  CYCLE_LENGTH_OPTIONS,
  formatDueDate,
  calculateDueDate,
} from './helpers'
import { useNavigate } from 'react-router-dom-v5-compat'
import { Button, Dropdown } from 'baby-design'
import Wave from './components/Wave'
import Background from './components/Background'
import Guides from '../Guides/Guides'
import FrequentlyAskedQuestions from './components/FrequentlyAskedQuestions/FrequentlyAskedQuestions'
import classNames from 'classnames'

const initialDate = sub(startOfToday(), { weeks: 2 })

interface DueDateFormProps {
  posts: Post[]
  longForm?: boolean
}

const DueDateForm: React.FC<DueDateFormProps> = ({
  posts,
  longForm = false,
}) => {
  const navigate = useNavigate()
  const [calculationMethod, setCalculationMethod] = useState<Option>(
    METHOD_OPTIONS[0]
  )
  const [selectedDate, setSelectedDate] = useState(initialDate)
  const [cycleLength, setCycleLength] = useState(
    CYCLE_LENGTH_OPTIONS.find((option) => option.label === '28 days')
  )

  const handleSubmit = () => {
    if (!isValid(selectedDate)) return
    const dueDate = calculateDueDate(selectedDate, calculationMethod)
    if (!dueDate) return

    navigate(`${dueDateCalculatorPath}/${formatDueDate(dueDate)}`)
  }

  return (
    <>
      <div className={css.DueDateForm}>
        <div className={css.DueDateForm__backgroundWrapper}>
          <div className={css.DueDateForm__wrapper}>
            <div className={css.DueDateForm__content}>
              <h1>Pregnancy due date calculator</h1>
              <p>
                Find out when you'll welcome your new arrival(s) into the world!{' '}
                <span className={css.DueDateForm__desktopOnly}>
                  Enter the first day of your last menstrual period or
                  conception date and we'll show you your estimated due date.
                  Plus, explore the exciting milestones ahead for you and your
                  baby, including insights into their zodiac sign, birthstone
                  and more!
                </span>
              </p>
              <div className={css.DueDateForm__form}>
                <h2>Calculation method</h2>
                <Dropdown
                  id="calculationMethod"
                  size="lg"
                  className={css.DueDateForm__dropdown}
                  value={calculationMethod}
                  onChange={(e) => setCalculationMethod(e as Option)}
                  options={METHOD_OPTIONS}
                  name="calculationMethod"
                />
                <DatePickerInput
                  className={css.DueDateForm__datePicker}
                  initialValue={initialDate}
                  handleChange={setSelectedDate}
                  name="startDate"
                />
                {calculationMethod.value === 'Last day of period' && (
                  <>
                    <h2>Average cycle length (days)</h2>
                    <Dropdown
                      size="lg"
                      className={css.DueDateForm__dropdown}
                      options={CYCLE_LENGTH_OPTIONS}
                      value={cycleLength}
                      onChange={(e) => setCycleLength(e as Option)}
                    />
                  </>
                )}
                <Button
                  size="md"
                  variant="primary"
                  disabled={!isValid(selectedDate)}
                  className={classNames(
                    css.DueDateForm__button,
                    css.DueDateForm__mobileOnly
                  )}
                  onClick={handleSubmit}
                >
                  Get my due date
                </Button>
                <Button
                  size="lg"
                  variant="primary"
                  disabled={!isValid(selectedDate)}
                  className={classNames(
                    css.DueDateForm__button,
                    css.DueDateForm__desktopOnly
                  )}
                  onClick={handleSubmit}
                >
                  Get my due date
                </Button>
              </div>
            </div>
            <Background
              className={classNames(
                css.DueDateForm__background,
                css.DueDateForm__desktopOnly
              )}
            />
          </div>
        </div>
        {longForm && (
          <>
            <Wave className={css.DueDateForm__wave} />
            <FrequentlyAskedQuestions className={css.DueDateForm__faq} />
            <div className={css.DueDateForm__guidesWrapper}>
              <Guides posts={posts} />
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default DueDateForm
