import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { find, get, map } from 'lodash'
import classNames from 'classnames'
import Big from 'big.js'
import {
  buildCartItems,
  getAmountEligibleForUserCredit,
  getEstimatedTax,
  getRegistryDiscountSubtotal,
  getShippingCost,
  getStoreCartItems,
  getSubTotal,
  getTotalQuantity,
  getRegistrySlugsAndTitles,
  getStoreCartItemsByRegistrySlug,
} from 'cart/lib'
import { CANCEL_RESERVATION, MARK_AS_PURCHASED } from 'cart/constants'
import { getRegistry } from 'cart/reducers'
import ReservedItemsHeader from 'cart/components/reserved-items-header'
// eslint-disable-next-line import/no-named-as-default
import StoreOrderItem from 'cart/components/store-order-item'
// eslint-disable-next-line import/no-named-as-default
import BorderedOrderWrapper from '../bordered-order-wrapper'
import BLCartOrderItem from '../bl-cart-order-item'
import StoreOrderSummary from '../store-order-summary'
import BLMaintenanceMessage from '../bl-maintenance-message'
import { setActiveModal } from '../../actions'
import { CartShape, RegistryShape } from '../../lib/prop-types'

export const BLCartOrder = ({
  amazonCart,
  availableCredit,
  cart,
  maintenanceMode,
  onRemoveCartItem,
  onSetReservationToMarkAsPurchased,
  onSetReservationToRemove,
  registry,
  reservations,
  taxRate,
  useRegistryDiscount,
  footerComponent,
}) => {
  // Calculate order amounts
  const cartItems = buildCartItems(
    amazonCart,
    cart,
    reservations,
    'Babylist',
    useRegistryDiscount
  )
  const { productSurcharge, requiresReservationCreation } = cart
  const storeCartItems = getStoreCartItems(cartItems)
  const registries = getRegistrySlugsAndTitles({
    storeCartItems,
    registry,
    reservations,
  })
  const groupedStoreCartItems = getStoreCartItemsByRegistrySlug(cartItems)
  const subTotal = getSubTotal(cartItems)
  const totalQuantity = getTotalQuantity(reservations, cart)
  const estTax = getEstimatedTax(cartItems, taxRate, useRegistryDiscount)
  const shippingCost = getShippingCost(
    cartItems,
    useRegistryDiscount,
    productSurcharge
  )
  const registryDiscount = useRegistryDiscount
    ? getRegistryDiscountSubtotal(cartItems)
    : new Big(0)
  const babylistCredit = new Big(
    Math.min(
      availableCredit,
      getAmountEligibleForUserCredit(cartItems, taxRate, useRegistryDiscount)
    )
  )
  const creditRemaining = new Big(availableCredit).minus(babylistCredit)

  return (
    <BorderedOrderWrapper footerComponent={footerComponent}>
      <div className="mbn mrxl-md">
        {map(registries, (currentRegistry, index) => {
          return (
            <div
              className={classNames({ bbs: index !== registries.length - 1 })}
            >
              {currentRegistry && (
                <ReservedItemsHeader
                  className="h5 mtm mbn"
                  registry={currentRegistry}
                />
              )}
              {!currentRegistry && registries.length > 1 && (
                <div className="h5 mtl mbn text-bold text-category-underline">
                  Not from Registry
                </div>
              )}
              <ul className="list-bordered">
                {currentRegistry?.urlSlug == registry?.urlSlug &&
                  map(reservations, (reservation) => {
                    const currentOffer = find(
                      reservation.regItem.offers,
                      (o) => o.storeName === 'Babylist'
                    )

                    // If the user has entered a custom amount, use it as the price
                    if (currentOffer && reservation.giftAmount) {
                      currentOffer.price = reservation.giftAmount
                    }

                    return (
                      <li key={reservation.token}>
                        <StoreOrderItem
                          currentOffer={currentOffer}
                          reservation={reservation}
                          storeName={reservation.storeName}
                          useRegistryDiscount={useRegistryDiscount}
                          onSetPurchasedReservation={() =>
                            onSetReservationToMarkAsPurchased(reservation)
                          }
                          onSetReservationToRemove={() =>
                            onSetReservationToRemove(reservation)
                          }
                        />
                      </li>
                    )
                  })}
                {map(
                  groupedStoreCartItems[currentRegistry?.urlSlug || ''],
                  (cartItem) => (
                    <li key={cartItem.uuid}>
                      <BLCartOrderItem
                        cartItem={cartItem}
                        useRegistryDiscount={useRegistryDiscount}
                        onRemoveCartItem={onRemoveCartItem}
                      />
                    </li>
                  )
                )}
              </ul>
            </div>
          )
        })}
      </div>
      {maintenanceMode ? (
        <BLMaintenanceMessage className="mvl phs h6" />
      ) : (
        <StoreOrderSummary
          babylistCredit={babylistCredit}
          cartItems={cartItems}
          creditRemaining={creditRemaining}
          estTax={estTax}
          isEligibleForRegistryDiscount={cart.isEligibleForRegistryDiscount}
          productSurcharge={productSurcharge}
          registry={registry}
          registryDiscount={registryDiscount}
          reservations={reservations}
          shippingCost={shippingCost}
          subTotal={subTotal}
          totalQuantity={totalQuantity}
          useRegistryDiscount={useRegistryDiscount}
          requiresReservationCreation={requiresReservationCreation}
        />
      )}
    </BorderedOrderWrapper>
  )
}

BLCartOrder.propTypes = {
  amazonCart: PropTypes.object,
  availableCredit: PropTypes.instanceOf(Big).isRequired, // Big.js object
  cart: PropTypes.shape(CartShape).isRequired,
  onRemoveCartItem: PropTypes.func.isRequired,
  reservations: PropTypes.arrayOf(PropTypes.object).isRequired,
  taxRate: PropTypes.number.isRequired,
  useRegistryDiscount: PropTypes.bool,
  maintenanceMode: PropTypes.bool,
  onSetReservationToMarkAsPurchased: PropTypes.func.isRequired,
  onSetReservationToRemove: PropTypes.func.isRequired,
  registry: PropTypes.shape(RegistryShape),
  footerComponent: PropTypes.node,
}

BLCartOrder.defaultProps = {
  amazonCart: null,
  maintenanceMode: false,
  useRegistryDiscount: false,
  registry: null,
  footerComponent: null,
}

const mapStateToProps = (state) => ({
  availableCredit: Big(get(state.user, 'availableCredit', 0)),
  registry: getRegistry(state),
})

const mapDispatchToProps = (dispatch) => ({
  onSetReservationToRemove: (reservation) =>
    dispatch(setActiveModal(CANCEL_RESERVATION, { reservation })),
  onSetReservationToMarkAsPurchased: (reservation) =>
    dispatch(setActiveModal(MARK_AS_PURCHASED, { reservation })),
})

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(BLCartOrder)
