import PropTypes from 'prop-types'
import { Media } from 'react-bootstrap'
import Tappable from 'react-tappable/lib/Tappable'
import { map, range } from 'lodash'
import classNames from 'classnames'
import Image from 'components/image'
import Link from 'components/link'
import { addOrReplaceImageTransform } from 'lib/cloudinary'
import { helloBabyPostPath } from 'lib/urls'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import NiceModal from '@ebay/nice-modal-react'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { AddCashFundDrawer } from 'src/routes/(shop)/components/AddCashFundDrawer/AddCashFundDrawer'
import ProductCardGhost from '../product-card-ghost'
import ProductCardV2 from '../ProductCardV2'
import css from './product-grid.scss'

const CASH_CATEGORY_ID = -13

const ProductGrid = ({ activeSlugSet, className, productCards, guides }) => {
  const [currentUser] = useCurrentUser()
  const { flagValue: isCashFundDrawerEnabled } = useFeatureFlag(
    '24d_ff_regex_cash_fund_plp_drawer',
    false
  )

  const NUM_GHOSTS = 6
  const renderGhostGrid = () => (
    <>
      {map(range(NUM_GHOSTS), (idx) => (
        <div className={css['ProductGrid__grid-item--product']} key={idx}>
          <ProductCardGhost />
        </div>
      ))}
    </>
  )

  const renderGrid = () => {
    const gridItems = map(productCards, (card, idx) => {
      const { productBadge, ...otherCardProps } = card

      const handleClick = (e) => {
        if (card.isCrowdfund && isCashFundDrawerEnabled && currentUser) {
          e.preventDefault()
          NiceModal.show(AddCashFundDrawer, {
            regItem: {
              categoryId: CASH_CATEGORY_ID,
              registryId: currentUser.registryId,
              productId: card.productId,
              quantity: 1,
              title: card.title,
              isCrowdfund: true,
              isTitleEditable: true,
              imgUrl: card.imageUrl,
            },
          })
        }
      }

      return (
        <div
          className={css['ProductGrid__grid-item--product']}
          key={`${card.title}-${idx}`}
        >
          <ProductCardV2
            {...otherCardProps}
            __forcePageLoad
            position={idx + 1}
            slugSet={activeSlugSet}
            onClick={handleClick}
          />
        </div>
      )
    })
    return gridItems
  }

  const renderGuides = () => {
    if (productCards?.length >= 7 && guides && guides.length > 0) {
      const guide = guides[0] // only show the first guide for now
      return (
        <div className={css['ProductGrid__grid-item--guide']} key={guide.title}>
          <GuideCard className={css.guideCard} post={guide} />
        </div>
      )
    }
  }

  return (
    <NiceModal.Provider>
      <div
        className={classNames(
          css.ProductGrid,
          css['ProductGrid--3-col'],
          className
        )}
      >
        {productCards ? renderGrid() : renderGhostGrid()}
        {productCards ? renderGuides() : null}
      </div>
    </NiceModal.Provider>
  )
}

ProductGrid.propTypes = {
  className: PropTypes.string,
  guides: PropTypes.array,
  productCards: PropTypes.array,
  activeSlugSet: PropTypes.array,
}

ProductGrid.defaultProps = {
  className: '',
  guides: [],
  productCards: [],
  activeSlugSet: [],
}

export const GuideCard = ({ className, post }) => (
  <Tappable
    className="text-body"
    component={Link}
    url={helloBabyPostPath(post.slug)}
  >
    <Media className={classNames(className, 'position-rel')}>
      <div className="hover-overlay hover-darken">
        {/* width % attempts to closely match the width of the product card */}
        <Media.Left className="pan" style={{ width: '44%' }}>
          <Image
            alt={post.title}
            src={addOrReplaceImageTransform(
              post.featuredImage.url,
              'c_fill,g_auto:face,h_900,w_500'
            )}
            style={{ width: '100%' }}
          />
        </Media.Left>
        <Media.Body className="bbs brs bts mbm">
          <div className="center-y pal">
            <div className={classNames('h6', css.guideCardLabel)}>Guide</div>
            <div className={`media-heading ${css.guideCardTitle}`}>
              {post.title}
            </div>
            <p className={css.guideCardTeaser}>{post.teaser}</p>
            {/* Make this look like a link, but in fact the entire card is clickable */}
            <p className="h7 text-brand-info text-uppercase">Read More</p>
          </div>
        </Media.Body>
      </div>
    </Media>
  </Tappable>
)

GuideCard.propTypes = {
  className: PropTypes.string,
  post: PropTypes.object.isRequired,
}

GuideCard.defaultProps = {
  className: '',
}

export default ProductGrid
