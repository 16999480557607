import classNames from 'classnames'
import PropTypes from 'prop-types'
import { registryPath } from 'lib/urls'
import { TextLink } from 'baby-design'

const ReservedItemsHeader = ({ registry, className }) => {
  return (
    <div className={classNames(className, 'text-bold text-category-underline')}>
      From{' '}
      <TextLink href={registryPath(registry.urlSlug)}>
        {registry.title}
      </TextLink>
    </div>
  )
}

ReservedItemsHeader.propTypes = {
  className: PropTypes.string,
  registry: PropTypes.object.isRequired,
}

ReservedItemsHeader.defaultProps = {
  className: undefined,
}

export default ReservedItemsHeader
