import { add, format } from 'date-fns'

export type Option = {
  label: string
  value: string
}

export const METHOD_OPTIONS: Option[] = [
  { label: 'Last period', value: 'Last day of period' },
  { label: 'Conception date', value: 'Conception date' },
  { label: 'I know my due date', value: 'I know my due date' },
]

export const CYCLE_LENGTH_OPTIONS: Option[] = [
  { label: "I don't know", value: '28 days' },
  { label: '20 days', value: '20 days' },
  { label: '21 days', value: '21 days' },
  { label: '22 days', value: '22 days' },
  { label: '23 days', value: '23 days' },
  { label: '24 days', value: '24 days' },
  { label: '25 days', value: '25 days' },
  { label: '26 days', value: '26 days' },
  { label: '27 days', value: '27 days' },
  { label: '28 days', value: '28 days' },
  { label: '29 days', value: '29 days' },
  { label: '30 days', value: '30 days' },
  { label: '31 days', value: '31 days' },
  { label: '32 days', value: '32 days' },
  { label: '33 days', value: '33 days' },
  { label: '34 days', value: '34 days' },
  { label: '35 days', value: '35 days' },
  { label: '36 days', value: '36 days' },
  { label: '37 days', value: '37 days' },
  { label: '38 days', value: '38 days' },
  { label: '39 days', value: '39 days' },
  { label: '40 days', value: '40 days' },
]

const lengthOfPregnancy = 266
const averageLutealPhase = 14

export const getDueDateFromLastPeriod = (date: Date): Date =>
  add(date, { days: averageLutealPhase + lengthOfPregnancy })

export const getDueDateFromConceptionDate = (date: Date): Date =>
  add(date, { days: lengthOfPregnancy })

export const formatDueDate = (date: Date): string => format(date, 'yyyy-MM-d')

export const calculateDueDate = (
  selectedDate: Date,
  calculationMethod: Option
): Date | null => {
  switch (calculationMethod.value) {
    case 'Last day of period':
      return getDueDateFromLastPeriod(selectedDate)
    case 'Conception date':
      return getDueDateFromConceptionDate(selectedDate)
    case 'I know my due date':
      return selectedDate
    default:
      return null // or handle unexpected values appropriately
  }
}
