import React, { useCallback, useState } from 'react'
import css from './EmailCapture.scss'
import { TextInput, Button, LoadingDots } from 'baby-design'
import BackgroundLeft from '../../svg/BackgroundLeft.svg'
import BackgroundRight from '../../svg/BackgroundRight.svg'
import BackgroundSmall from '../../svg/BackgroundSmall.svg'
import Message from '../../svg/Message.svg'
import Mailbox from '../../svg/Mailbox.svg'
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import GoogleRecaptchaDisclosureTag from 'components/google-recaptcha-disclosure-tag'
import {
  RECAPTCHA_V3_SITE_KEY,
  USER_SOURCE_EMAIL_PREGNANCY_WIDGET,
} from 'shared/constants'
import { EmailTypes } from 'components/email-callout/email-callout'
import * as api from 'pages/pregnancy-emails/api'
import { useTracking, track, withContextualizedTracking } from 'lib/analytics'
import { parseErrors } from 'lib/api-utils'
import classNames from 'classnames'

interface EmailCaptureProps {
  className?: string
  dueDate: Date
}

const EmailCapture: React.FC<EmailCaptureProps> = ({ dueDate }) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const tracker = useTracking()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available')
      return
    }

    return await executeRecaptcha('hello_baby_email_signup')
  }, [executeRecaptcha])

  const handleError = (errors: any) => {
    // Email can't be blank, Email is invalid, and Email is too short (minimum is 3 characters)
    if (errors.includes("Email can't be blank")) {
      setError('Enter your email')
      return
    }
    if (errors.includes('Email is invalid')) {
      setError('Enter a valid email (name@company.com)')
      return
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setSubmitting(true)
    setError('')

    if (!email) {
      setSubmitting(false)
      setError('Enter your email')
      return
    }
    if (!email.includes('@') || !email.includes('.')) {
      setSubmitting(false)
      setError('Email is invalid')
      return
    }

    const token = await handleReCaptchaVerify()
    let formData = null
    formData = {
      emailType: EmailTypes.Newsletter,
      user: {
        email,
        family: {
          arrival_date: dueDate,
        },
        source: USER_SOURCE_EMAIL_PREGNANCY_WIDGET,
      },
      recaptcha_token: token,
    }

    api
      .createUser(formData)
      .then(({ user: { id: userId, email: userEmail } }) => {
        setSubmitting(false)
        setSubmitted(true)
        setError('')
        sendTrackingEvents({ userEmail, userId })
        setSubmitted(true)
      })
      .catch((resp) => {
        setSubmitting(false)
        const apiErrors = parseErrors(resp)
        if (apiErrors._error) {
          handleError(apiErrors._error)
        } else {
          setError('There was an unexpected error.')
        }
      })
  }

  const sendTrackingEvents = ({
    userEmail,
    userId,
  }: {
    userEmail: string
    userId: number
  }) => {
    if (typeof window != 'undefined' && typeof window.ga != 'undefined') {
      window.ga('set', 'page', `${window.location.pathname}/submitted`)
      window.ga('send', 'pageview')
      window.ga('send', {
        hitType: 'event', // required
        eventCategory: 'Email', // required
        eventAction: 'Subscribe', // required
        eventValue: 0, // required
      })
    }

    tracker.trackEvent({
      event: track.emailCaptured,
      email: userEmail,
      userId,
      eventLocation: track.EventLocation.GUIDE,
      eventUrl: window.location.href,
    })

    // Facebook tracking
    window.fbq('trackCustom', 'EmailSubscribe')
  }

  return (
    <>
      <div className={css.EmailCapture}>
        <BackgroundSmall
          className={classNames(
            css.EmailCapture__bgSmall,
            css.EmailCapture__mobileOnly
          )}
        />
        <BackgroundLeft
          className={classNames(
            css.EmailCapture__bgLeft,
            css.EmailCapture__desktopOnly
          )}
        />
        {submitting && <LoadingDots className={css.EmailCapture__loading} />}
        {!submitting && !submitted && (
          <div className={css.EmailCapture__wrapper}>
            <h2>Never miss a milestone</h2>
            <p>
              Sign up to get week-by-week updates about your pregnancy,
              including common symptoms and baby's development during each
              pregnancy trimester.
            </p>
            <form className={css.EmailCapture__form} onSubmit={handleSubmit}>
              <div className={css.EmailCapture__inputWrapper}>
                <TextInput
                  LeftIcon={<Message />}
                  inputProps={{
                    placeholder: 'Enter your email',
                    type: 'email',
                    value: email,
                    onChange: (e) => setEmail(e.target.value),
                  }}
                  size="lg"
                  status={error ? { type: 'error', message: error } : undefined}
                />
                <div className={css.EmailCapture__desktopOnly}>
                  <Button
                    variant="primary"
                    type="submit"
                    size="lg"
                    className={classNames(css.EmailCapture__button)}
                    disabled={submitting}
                  >
                    Sign up
                  </Button>
                </div>
                <div className={css.EmailCapture__mobileOnly}>
                  <Button
                    variant="primary"
                    type="submit"
                    size="md"
                    className={classNames(css.EmailCapture__button)}
                    disabled={submitting}
                  >
                    Sign up
                  </Button>
                </div>
              </div>
              <GoogleRecaptchaDisclosureTag
                className={css.EmailCapture__grecaptchaLegalNotice}
              />
            </form>
          </div>
        )}
        {!error && !submitting && submitted && (
          <div className={css.EmailCapture__wrapper}>
            <Mailbox className={css.EmailCapture__mailbox} />
            <h2>Yay! Weekly wisdom is coming your way.</h2>
            <p>Check your inbox to stay informed every step of the way.</p>
          </div>
        )}
        <BackgroundRight
          className={classNames(
            css.EmailCapture__bgRight,
            css.EmailCapture__desktopOnly
          )}
        />
      </div>
    </>
  )
}

const EmailCaptureContext = ({ className, dueDate }: EmailCaptureProps) => (
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_SITE_KEY}>
    <EmailCapture className={className} dueDate={dueDate} />
  </GoogleReCaptchaProvider>
)

export default withContextualizedTracking()(EmailCaptureContext)
