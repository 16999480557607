export const FORMAT_DATE_LONG = 'mmm dS, yyyy'
export const msPerDay = 1000 * 60 * 60 * 24
export const msPerWeek = msPerDay * 7
export const msPerMin = 1000 * 60

export const today = () => {
  const date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

export const ageNextBirthday = (birthdateStr) => {
  const birthDateObj = new Date(birthdateStr)
  const today = new Date()
  let age = today.getFullYear() - birthDateObj.getFullYear()

  const isBirthdayToday =
    today.getDate() === birthDateObj.getDate() &&
    today.getMonth() === birthDateObj.getMonth()

  if (isBirthdayToday) return age

  const monthDiff = today.getMonth() - birthDateObj.getMonth()

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
  ) {
    age -= 1
  }

  return age + 1
}

export const daysUntil = (date) => {
  if (typeof date === 'string') {
    date = parseLocalISODate(date)
  }
  return Math.floor((date.getTime() - today().getTime()) / msPerDay)
}

export const weeksFromToday = (numWeeks) =>
  new Date(today().getTime() + numWeeks * msPerWeek)

export const weeksAgo = (date) => {
  if (typeof date === 'string') {
    date = parseLocalISODate(date)
  }
  return Math.floor((today().getTime() - date.getTime()) / msPerWeek)
}

export const parseLocalISODate = (string) => {
  // Parse a ISO string with the time being options in the current timezone
  const date = string.split(/\D/)
  const dateLength = date.length
  return new Date(
    date[0],
    date[1] - 1,
    date[2],
    dateLength > 3 ? date[3] : '',
    dateLength > 4 ? date[4] : '',
    dateLength > 5 ? date[5] : ''
  )
}

export const minutesSince = (datetime) => {
  if (typeof datetime === 'string') {
    datetime = new Date(datetime)
  }
  return Math.floor((Date.now() - datetime.getTime()) / msPerMin)
}
