import React, { useContext } from 'react'
import EZAddToCartAlertsContext from 'src/contexts/EZAddToCartAlertsContext'
import { DangerAlert } from 'components/alerts'
import css from './EZAddToCartAlerts.styles.scss'

const EZAddToCartAlerts: React.FC = () => {
  const { clearAlerts, alerts } = useContext(EZAddToCartAlertsContext)

  if (alerts.length === 0) {
    return null
  }

  return (
    <DangerAlert size="large" onDismiss={clearAlerts} closeLabel="close">
      <div>
        <h3 className={css.EZAddToCartAlerts__heading}>
          Action required before checkout
        </h3>
      </div>
      {alerts.map((alert, index) => (
        <div key={index} className={css.EZAddToCartAlerts__alert}>
          {alert.message}
        </div>
      ))}
    </DangerAlert>
  )
}

export default EZAddToCartAlerts
