import { connect } from 'react-redux'
import { successMessage } from 'lib/flash-message'

import { Button } from 'baby-design'
import { PriceDetails } from 'src/types/priceDetails'
import { useQueryClient } from '@tanstack/react-query'
import { CACHE_KEY } from 'src/routes/(shop)/api/cart'
import { track, useTracking } from '../../../lib/analytics'
import { openQuickViewModal } from '../../../registry/modals/actions'
import { InfoButton } from '../../../components/buttons'
import AddToCartButton from '../../../store/components/add-to-cart-button'

export interface ProductProps {
  productId: number
  title: string
  optionsCta?: string | null
  price?: any
  priceDetails?: PriceDetails
  isElectronicGiftCard: boolean
}

export interface ProductCardActionProps {
  originalCartSubTotal?: number | null
  product: ProductProps
  openModal(productId: number, onAddToCartSuccess: any): any
  useHeartbeatDS?: boolean
}

export function ProductCardAction({
  product,
  openModal,
  useHeartbeatDS,
}: ProductCardActionProps) {
  const {
    productId,
    optionsCta,
    title,
    price,
    priceDetails,
    isElectronicGiftCard,
  } = product
  const currentPrice = price?.current ? Number(price.current) : undefined
  const productType = isElectronicGiftCard
    ? track.ProductType.GIFT_CARD
    : track.ProductType.PRODUCT
  const productSaleType =
    priceDetails?.saleAttributes?.saleType === 'active_sale'
      ? 'price_sale'
      : priceDetails?.saleAttributes?.saleType
  const tracker = useTracking()
  const queryClient = useQueryClient()

  const handleAddToCartSuccess = (
    selectedProductId: number,
    quantity: number,
    productPrice: number,
    productType: string,
    productSaleType: string
  ) => {
    queryClient.invalidateQueries(CACHE_KEY)

    tracker.trackEvent({
      event: track.addedToCart,
      cartTypes: [track.CartTypes.STORE],
      cartStores: [track.CartStores.BABYLIST_STORE],
      storeAdded: track.StoreAdded.BABYLIST,
      eventLocation: optionsCta
        ? track.EventLocation.CART_MINUS_RECOMMENDATIONS_QUICK_VIEW_MODAL
        : track.EventLocation.CART_MINUS_RECOMMENDATIONS_CAROUSEL,
      subscribeToMarketingEmail: false,
      cartItemQuantity: quantity,
      productType,
      productPrice,
      productIds: [selectedProductId],
      ...(productSaleType && { saleType: [productSaleType] }),
    })
  }

  if (optionsCta) {
    return useHeartbeatDS ? (
      <Button
        size="md"
        variant="primary"
        onClick={(e: any) => {
          e.preventDefault()
          openModal(productId, handleAddToCartSuccess)
        }}
      >
        Add to Cart
      </Button>
    ) : (
      <InfoButton
        pill
        onClick={(e: any) => {
          e.preventDefault()
          openModal(productId, handleAddToCartSuccess)
        }}
      >
        Add to Cart
      </InfoButton>
    )
  }
  return (
    <AddToCartButton
      useHeartbeatDS
      useInfoButtonStyle
      buttonStyle={{ pill: true, block: false }}
      productId={productId}
      productPrice={currentPrice}
      productSaleType={productSaleType}
      productType={productType}
      quantity={1}
      style={undefined}
      onSuccess={(id, qty, price, type, saleType) => {
        handleAddToCartSuccess(id, qty, price, type, saleType)
        successMessage(`${title} added to cart!`)
      }}
    />
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  openModal: (productId: number, onAddToCartSuccess: void) =>
    dispatch(openQuickViewModal(productId, onAddToCartSuccess)),
})

export default connect(undefined, mapDispatchToProps)(ProductCardAction)
